import axios from 'axios';

export default function() {
  return {

    error: false,

    send() {
      let data = new FormData(this.$el);
      axios.post('/actions/site-module/newsletter/preference', data)
        .then(resp => {
          const event = new CustomEvent('flash', {
            detail: {
              status: 'success',
              message: 'Newsletter preference updated'
            }
          });
          window.dispatchEvent(event);
          console.log('Preference updated');
          this.error = false;
        })
        .catch(err => {
          this.error = true;
        });
    }
  };
}
