import Alpine from 'alpinejs';
import ui from '@alpinejs/ui';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';
import persist from '@alpinejs/persist';
import client from './shopify/client.js';
import lineItem from './shopify/lineItem.js';
import product from './shopify/product.js';
import notification from './notification.js';
import mailchimp from './mailchimp.js';
import responsiveAsset from './responsiveAsset.js';
import embedAssetResizer from './embedAssetResizer.js';
import newsletterPreference from './newsletterPreference.js';
import mailchimpPopup from './mailchimpPopup.js';
import { scrollToReveal } from './productHeader.js';
import { slideshow, shopSlideshow } from './swiper.js';
import { formie } from './formie.js';
import { artwork } from './artwork.js';
import { login } from './user.js';
import { app } from './app.js';

/* plugins */
Alpine.plugin(ui);
Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(persist);

/* data components */
Alpine.data("product", product);
Alpine.data("lineItem", lineItem);
Alpine.data("notification", notification);
Alpine.data("scrollToReveal", scrollToReveal);
Alpine.data("slideshow", slideshow);
Alpine.data("shopSlideshow", shopSlideshow);
Alpine.data("formie", formie);
Alpine.data("artwork", artwork);
Alpine.data("login", login);
Alpine.data("mailchimp", mailchimp);
Alpine.data("responsiveAsset", responsiveAsset);
Alpine.data("embedAssetResizer", embedAssetResizer);
Alpine.data("newsletterPreference", newsletterPreference);
Alpine.data("mailchimpPopup", mailchimpPopup);
Alpine.data("app", app);

/* our store - see scripts.twig for init */
Alpine.store('cart', client(
  window.SHOPIFY_HOSTNAME,
  window.SHOPIFY_STOREFRONT_ACCESS_TOKEN
));

/* start your engines */
window.Alpine = Alpine;
Alpine.start();
